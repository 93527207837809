<!--
* 创建人：邓国伦
* 日 期：
* 描 述：选择学期自定义组件
-->
<!--view 模板-->
<template>
  <div>
    <el-select
      v-model="val"
      placeholder="请选择"
      :clearable="clearable"
      size="small"
      style="width: 200px"
    >
      <el-option
        v-for="item in options"
        :key="item.id"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </div>
</template>
<!-- javascript脚本-->
<script>
import { GetList } from '../../api/system'
export default {
  props: {
    value: { type: String },
    clearable: { type: Boolean, default: true },
    selectdqxq: { type: Boolean, default: false }, // 是否选择当前学期 默认 否
  },
  data() {
    return {
      val: this.value,
      options: [],
    }
  },
  watch: {
    value(newval) {
      this.val = newval
    },
    val(newval) {
      this.$emit('input', newval)
    },
  },
  created() {
    GetList().then((res) => {
      res.data.forEach((v) => {
        this.options.push({
          id: v.id,
          value: v.id,
          label: v.ksnf + '-' + v.jsnf + '学年第' + v.xq + '学期',
        })
        if (this.selectdqxq) {
          if ((!this.val || this.val === '') && v.sfdqxq === 1) {
            this.val = v.id
          }
        }
      })
    })
  },
  methods: {},
  mounted() {},
}
</script>
<!-- CSS样式 -->
<style></style>
